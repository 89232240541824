import React, { useEffect } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import { useForm } from "react-hook-form";
import TextInput from "../../components/formInputs/TextInput";
import { Link } from "react-router-dom";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import roundLogo from "../../images/round-logo.svg";
import useMutationRateLimitingAPI from "../../hooks/useMutationRateLimitingAPI";
import { encryptData } from "../../utility/encryption";
import { capitalizeWords } from "../../utility/capitalizeWords";
import Spinner from "../../components/Spinner/Spinner";
import toast, { Toaster } from "react-hot-toast";

const URL = `${process.env.REACT_APP_BACKEND}/auth/signup`;

const Signup = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const resetFields = () => reset({ name: "", email: "", password: "" });

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const onError = (error) => {
		const errorResponse = error?.response?.data;
		if (errorResponse) {
			toast.error(errorResponse?.message);
		}
	};
	const onSuccess = (response) => {
		resetFields();
		toast.success("Signup success!! Verification email has been sent to your email.");
	};
	const { mutate, isPending } = useMutationRateLimitingAPI({
		limit: 5,
		timeWindow: 1000,
		onSuccess,
		onError,
	});

	const onSubmit = async (data) => {
		const { name, email, password } = data;
		const encryptedPassword = encryptData(password);
    
		const userData = {
			name: capitalizeWords(name),
			email,
			password: encryptedPassword,
			currentTime: new Date(),
		};

		const encryptedPayload = encryptData(JSON.stringify(userData));
		const payload = {
			token: encryptedPayload,
		};
		mutate({ url: URL, data: payload });
	};

	return (
		<NonProtectedLayout>
			<SpaceWrapper>
				<div className="px-[170px] mobile:px-[0px] py-16 text-white">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex flex-col gap-9 mobile:gap-6 bg-primaryBlue relative rounded-[20px] px-[300px] mobile:px-10 pt-[153px] mobile:pt-16 pb-7"
					>
						<h2 className="text-center text-[32px] mobile:text-[24px] font-audioWide">SIGN UP</h2>
						<div className="flex flex-col gap-6">
							<TextInput
								type="text"
								name="name"
								placeholder="Full Name"
								register={register}
								errors={errors}
								isRequired={true}
								maxLength={60}
							/>
							<TextInput
								type="email"
								name="email"
								placeholder="Email Address"
								register={register}
								errors={errors}
								isRequired={true}
								validateInput
								pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
							/>
							<TextInput
								type="password"
								name="password"
								placeholder="Password"
								register={register}
								errors={errors}
								isRequired={true}
							/>
						</div>
						<div className="flex flex-col gap-6 mobile:gap-3 items-center justify-center">
							<button
								disabled={isPending}
								type="submit"
								className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] w-[130px] px-5 hover:scale-110 transition-transform disabled:bg-opacity-70 disabled:cursor-not-allowed disabled:scale-100"
							>
								{isPending ? <Spinner /> : "Sign Up"}
							</button>
							<p className="text-md text-sm text-center">
								Have already an account?{" "}
								<Link to="/login" className="underline">
									Log in
								</Link>{" "}
								instead.
							</p>
						</div>
						<div className="w-[180px] h-[180px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
							<LazyImageObjectFit
								src={roundLogo}
								alt="Taara Quest Logo"
								className="object-cover"
								bg="none"
							/>
						</div>
					</form>
				</div>
				<Toaster />
			</SpaceWrapper>
		</NonProtectedLayout>
	);
};

export default Signup;
