import React, { useEffect } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import "./Assessment.css";
import LandingHeroImage from "../../components/ambassadors/LandingHeroImage/LandingHeroImage";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import LazyVideo from "../../components/LazyVideo/LazyVideo";

import arrowDown from "../../images/arrow-down.png";
import message from "../../images/assessment/whatYouWillLearn/multiple_messages.svg";
import positiveDynamic from "../../images/assessment/whatYouWillLearn/positive_dynamic.svg";
import about from "../../images/assessment/whatYouWillLearn/about.svg";
import PrimaryButton from "../../components/button/Button";
import Header from "../../components/Header/Header";

const whatYouWillLearn = [
  { icon: message, text: "Your Primary Communication Archetype" },
  { icon: positiveDynamic, text: "Areas for Growth" },
  { icon: about, text: "Practical Tips for Roleplaying" },
];

const Assessment = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <NonProtectedLayout>
      <Header
        title="Communication Assessment: AI Insights for Women in Leadership"
        description="How to Improve Communication Skills | Taara Quest Free Assessment"
        keywords=", AIbased career coaching, Women in leadership, Empowerment in tech, Authentic leadership, Tools for confidence at work, Overcoming limiting beliefs, Roleplay learning tools, Work-life balance"
      />

      <div className="AssessmentContainer">
        <LandingHeroImage
          alt="Ambassador Hero Image"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/assessment/Hero_Section+Image.webp"
          heading="ARE YOU AN EFFECTIVE COMMUNICATOR?"
          ctaText="Take Assessment"
          onClick={() =>
            window.open(
              "https://comuunicationstylefreeassessment.scoreapp.com/ ",
              "_blank"
            )
          }
        />

        {/***************  Why is the Important *****************/}
        <div className="flex flex-col gap-11 items-center mt-12 mobile:mt-6 mobile:gap-6">
          <div>
            <h3 className="font-audioWide text-primaryPink text-[43px] text-center mobile:text-sm">
              Why is that important?
            </h3>
            <h3 className="font-nohemi text-primaryBlue text-[43px] text-center mobile:text-sm">
              You May Wonder.
            </h3>
          </div>
          <p className="font-nohemi text-[20px] text-center text-gray w-[40%] mobile:text-[12px] mobile:w-[60%]">
            Well, communication is the cornerstone of effective leadership,
            teamwork, relationships and so much more!
          </p>
          <p className="font-nohemi text-[20px] font-bold mobile:text-[12px]">
            We can't NOT communicate.
          </p>
        </div>

        {/***************  Assessment Design Section *****************/}
        <div className="grid grid-cols-2 mt-12 gap-6 mobile:grid-cols-1 mobile:mt-6 mobile:gap-3">
          <div className="col-span-1 order-1 mobile:order-2 bg-primaryBlue rounded-[20px] h-[416px] p-16 flex flex-col gap-16 mobile:px-13 py-8 mobile:h-auto">
            <div className="w-6 h-6 relative mobile:hidden">
              <img
                src={arrowDown}
                alt="Arrow Down"
                className="w-full h-full object-cover"
              />
            </div>

            <p className="text-[30px] font-nohemi text-white mobile:text-[15px] mobile:text-center ">
              This assessment is designed to help you understand how you
              naturally navigate conversations, share ideas, and connect with
              others.
            </p>
          </div>
          <div className="col-span-1 rounded-[20px] h-[416px] order-2 mobile:order-1 mobile:h-[224px]">
            <LazyImageObjectFit
              src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/assessment/Explanation-Image.webp"
              alt="Assessement Design"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </div>
        </div>

        {/***************  Identifying Section *****************/}
        <div className="flex flex-col items-center gap-10 text-center my-12 mobile:my-6 mobile:gap-5">
          <h2 className="text-[43px] font-nohemi text-primaryBlue w-[60%] leading-tight mobile:text-xl mobile:w-[80%]">
            By identifying your primary communication archetype
          </h2>
          <p className="text-xl text-secondaryGray font-nohemi w-[30%] mobile:w-[80%] mobile:text-[12px] mobile:leading-tight">
             whether you're a Diplomat, Listener, Inspirer, Analyst, or Straight
            Talker — you will gain a deeper understanding of your strengths and
            areas where you can grow. 
          </p>
        </div>

        {/***************  Discover Section *****************/}
        <div className="w-full flex flex-col gap-6 items-center py-24 bg-[#E2B44B] rounded-[20px] text-white text-center mobile:py-12 mobile:px-16 mobile:gap-2">
          <h3 className=" font-audioWide text-[43px] w-[50%] leading-tight mobile:text-sm mobile:w-full">
            DISCOVER YOUR REAL WORLD IMPACT
          </h3>
          <p className="font-nohemi text-xl mobile:text-[12px]">
            Five minutes is all you need!
          </p>
        </div>

        {/** End of Assessment Section **/}
        <div className="flex flex-col items-center gap-16 my-12 mobile:my-6 mobile:gap-8">
          <h2 className="text-desktopHeading text-primaryBlue text-center w-[50%] mobile:text-mobileHeadiang">
            By the end of this assessment, you will learn:
          </h2>
          <div className="w-full grid grid-cols-3 gap-3 text-white bg-transparent mobile:grid-cols-1">
            {whatYouWillLearn.map((item) => (
              <div className="col-span-1 bg-primaryPink flex flex-col justify-between rounded-[20px] h-[479px] py-12 px-8 shadow-primaryCardShadow mobile:h-[296px] mobile:px-8 mobile:py-9">
                <div className="w-16 h-16 mobile:w-9 mobile:h-9">
                  <LazyImageObjectFit
                    src={item.icon}
                    alt={item.text}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-nohemi text-[27px] w-[50%] leading-tight mobile:text-xl">
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        </div>
        <LazyVideo
          className="w-full h-[720px] rounded-[20px] mobile:h-auto"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/assessment/169+Communication+Assessment+video_16_9.mp4"
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/assessment/Assessment_Video_Thumbnail.webp"
        />
        {/** Our Assessment is designed Section **/}
        <div className="flex flex-col items-center gap-10 py-[112px] shadow-primaryCardShadow rounded-[20px] text-center mobile:gap-7 mobile:py-12">
          <h3 className="font-nohemi text-[32px] text-primaryBlue w-[60%] mobile:text-xl mobile:w-[80%]">
            Our assessment is designed to be better prepared for the immersive
            roleplay quests, making your journey more impactful and insightful.
          </h3>
          <PrimaryButton text="Let's Begin" />
          <p className="font-nohemi text-secondaryGray text-xl mobile:text-[12px]">
            It’s free and only takes five minutes.
          </p>
        </div>

        <LandingHeroImage
          alt="Ambassador Hero Image"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Taara+Quest+Game+Waitlist+Signup+Image.webp"
          heading="JOIN THE TAARA QUEST MOVEMENT"
          ctaText="Apply Now"
          onClick={() => null}
        />
      </div>
    </NonProtectedLayout>
  );
};

export default Assessment;
