import React, { useCallback, useEffect, useState } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "./Unity.css";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate } from "react-router-dom";

const TryTaara = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const router = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    unload, // Function to unload Unity
  } = useUnityContext({
    loaderUrl: "/unitybuild.loader.js",
    dataUrl:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/unitybuild.data",
    // "/unitybuild.data",
    codeUrl:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/unitybuild.wasm",
    // "/unitybuild.wasm",
    frameworkUrl: "/unitybuild.framework.js",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const handleSetAuthToken = useCallback(() => {
    if (isLoaded === false || isPlaying === true) {
      return;
    }
    setIsPlaying(true);
    sendMessage("ReactUnityCommunicator", "SetAuthToken", "");
  }, [isLoaded, isPlaying, sendMessage]);

  const handleInitiateLogin = useCallback((tag) => {
    console.log(`[React]InitiateLogin:`, tag);
    router("/login");
  }, []);

  const handleClickFullscreen = () => {
    if (isLoaded === false) {
      return;
    }
    requestFullscreen(true);
  };

  useEffect(() => {
    handleSetAuthToken();
  }, [isPlaying, handleSetAuthToken]);

  useEffect(() => {
    if (addEventListener) {
      addEventListener("InitiateLogin", handleInitiateLogin);
    }

    return () => {
      if (removeEventListener) {
        removeEventListener("InitiateLogin", handleInitiateLogin);
      }
    };
  }, [handleInitiateLogin, addEventListener, removeEventListener]);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      console.log("Unloading Unity...");
      sendMessage("ReactUnityCommunicator", "StopProcessing"); // Communicate with Unity to stop ongoing tasks
      unload().catch((error) =>
        console.error("Error while unloading Unity:", error)
      );
    };
  }, [unload, sendMessage]);

  return (
    <NonProtectedLayout>
      <div className="flex flex-col gap-4 items-center py-12">
        {isLoaded === false && (
          <div className={styles.loaderContainer}>
            {/* Circular Spinner */}
            <div className={styles.spinner}></div>

            {/* Progress Bar */}
            <div className={styles.loadingBar}>
              <div
                className={styles.loadingBarFill}
                style={{ width: `${loadingProgression * 100}%` }}
              />
            </div>

            {/* Loading Text */}
            <div className="w-full h-[300px] flex justify-center items-center">
              <div className="w-24 h-24 flex justify-center items-center border-2 border-black rounded-full">
                <p className={styles.loadingText}>
                  {Math.round(loadingProgression * 100)}%
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="w-full py-14 flex justify-center">
          <Unity
            unityProvider={unityProvider}
            style={{
              display: isLoaded ? "block" : "none",
              height: "400px",
              width: "800px",
            }}
          />
        </div>
        {isLoaded && (
          <div className="w-full flex justify-center pb-14">
            <PrimaryButton text="Full screen" onClick={handleClickFullscreen} />
          </div>
        )}
      </div>
    </NonProtectedLayout>
  );
};

export default TryTaara;
