import React from "react";
import describeScenario from "./../../../images/custom-quest/describeScenario.svg";
import practiceInRealTime from "./../../../images/custom-quest/practiceInRealTime.svg";
import reflectAndRefine from "./../../../images/custom-quest/reflectAndRefine.svg";
import appliInReallife from "./../../../images/custom-quest/appliInReallife.svg";
import PrimaryButton from "../../button/PrimaryButton";

const howItWorks = [
  { title: "Describe your scenario", icon: describeScenario },
  { title: "Practice in Real Time", icon: practiceInRealTime },
  { title: "Reflect and Refine", icon: reflectAndRefine },
  { title: "Apply in Real Life", icon: appliInReallife },
];

const HowItWorks = () => {
  return (
    <div className="flex flex-col gap-[57px] my-12">
      <h2 className="text-center text-primaryBlue font-audioWide text-[45px]">
        HOW IT WORKS
      </h2>
      <div className="px-24 flex flex-col gap-4 text-white">
        {howItWorks.map((eachScenario, idx) => (
          <div className="bg-primaryPink flex justify-center h-[187px] rounded-[16px]">
            <div className="flex items-center gap-[37px]">
              <img
                src={eachScenario.icon}
                alt={eachScenario.title}
                width={64}
                height={64}
              />
              <div className="text-[30px] leading-8 w-[239px] font-semibold">
                {eachScenario.title}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <PrimaryButton
          text="Create Your RealWorld Roleplay"
          className="bg-primaryBlue"
        />
      </div>
    </div>
  );
};

export default HowItWorks;
