import React from "react";
import "./LandingHeroImage.css";
import UpdatedLazyImageObjectFit from "../../LazyImage/UpdatedLazyImageObjectFit";
import clsxm from "../../../utility/clsxm";

const LandingHeroImage = ({
  src,
  alt,
  heading,
  ctaText,
  onClick,
  headingStyleClasses = "",
  ctaCustomClasses = "",
}) => {
  return (
    <div className="hero-container">
      <UpdatedLazyImageObjectFit
        src={src}
        alt={alt}
        bg="none"
        className="w-full h-[712px] mobile:h-[381px] bg-transparent rounded-[20px]"
      />
      <div className="overlayImage">
        <div className={clsxm("overlayImageText", headingStyleClasses)}>
          {heading}
        </div>
        <div
          className={clsxm("overlayImageButton w-[200px]", ctaCustomClasses)}
          onClick={onClick}
        >
          {ctaText}
        </div>
      </div>
    </div>
  );
};

export default LandingHeroImage;
