import React from "react";
import "./RealWorldRolePlay.css";

const RealWorldRolePlay = () => {
  return (
    <div className="rolePlayFeatures">
      <div className="rolePlayCard confidence">
        <div className="rolePlayCardIcon">
          <i className="fa-solid fa-person-rays icon-custom-styles"></i>
        </div>
        <div className="rolePlayCardTitle">TURN FEAR INTO CONFIDENCE</div>
      </div>
      <div className="rolePlayCard awareness">
        <div className="rolePlayCardIcon">
          <i className="fa-solid fa-hand-holding-heart"></i>
        </div>
        <div className="rolePlayCardTitle">GAIN SELF-AWARENESS</div>
      </div>
      <div className="rolePlayCard communication">
        <div className="rolePlayCardIcon">
          <i className="fa-solid fa-graduation-cap"></i>
        </div>
        <div className="rolePlayCardTitle">REFINE COMMUNICATION STYLE</div>
      </div>
      <div className="rolePlayCard transformation">
        <div className="rolePlayCardIcon">
          <i className="fa-solid fa-users"></i>
        </div>
        <div className="rolePlayCardTitle">LASTING TRANSFERMATION</div>
      </div>
    </div>
  );
};

export default RealWorldRolePlay;
