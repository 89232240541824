import React from "react";
import "./Practice.css";
import WhatAmbassadorsDoCard from "../CreteRolePlay/CreateRoleplay";

const Practice = () => {
  return (
    <div className="PracticeContainer mt-12 pt-36 px-24 mobile:my-24 mobile:pt-0 mobile:px-0  ">
      <div className="flex flex-col gap-12 mobile:gap-5 items-center">
        <div className="flex flex-col">
          <h2 className="text-primaryPink font-audioWide text-[32px] mobile:text-center mobile:text-lg">
            PRACTICE TOUGH
          </h2>
          <h2 className="text-primaryPink font-audioWide text-[32px] mobile:text-center mobile:text-lg">
            CONVERSATIONS
          </h2>
          <h2 className="text-primaryBlue font-nohemi text-[32px] mobile:text-center mobile:text-lg">
            in a sace space
          </h2>
        </div>
        <p className="w-[326px] mobile:w-[193px] text-gray-600 text-xl mobile:text-xs mobile:text-center">
          You face unique challenges in your career whether it's negotiating a
          raise, managing team dynamics, or handling critical client
          conversations.{" "}
        </p>
      </div>
    </div>
  );
};

export default Practice;
