import React from "react";
import "./TaaraWorldFeatures.css";

const TaaraWorldFeatures = () => {
  return (
    <div className="taaraWorldFeatures">
      <div className="taaraWorldCard courageCard">
        <div className="taaraWorldCardIcon">
          <i className="fa-solid fa-person-rays icon-custom-styles"></i>
        </div>
        <div className="taaraWorldCardTitle">COURAGE</div>
        <div className="taaraWorldCardContent">
          Find the inner strength inside you to stand up and speak out.
        </div>
      </div>
      <div className="taaraWorldCard empathyCard">
        <div className="taaraWorldCardIcon">
          <i className="fa-solid fa-hand-holding-heart"></i>
        </div>
        <div className="taaraWorldCardTitle">EMPATHY</div>
        <div className="taaraWorldCardContent">
          Embrace having compassion for not just others -- but yourself, too.
        </div>
      </div>
      <div className="taaraWorldCard learningCard">
        <div className="taaraWorldCardIcon">
          <i className="fa-solid fa-graduation-cap"></i>
        </div>
        <div className="taaraWorldCardTitle">LEARNING</div>
        <div className="taaraWorldCardContent">
          Expand your professional toolkit with lessons in communication and
          confidence.
        </div>
      </div>
      <div className="taaraWorldCard communityCard">
        <div className="taaraWorldCardIcon">
          <i className="fa-solid fa-users"></i>
        </div>
        <div className="taaraWorldCardTitle">COMMUNITY</div>
        <div className="taaraWorldCardContent">
          Connect with women around the world who are on their leadership
          journey just like you
        </div>
      </div>
    </div>
  );
};

export default TaaraWorldFeatures;
