import React from "react";
import "./YouAreNotAlone.css";
import CustomCarousel from "../CustomCarousel/CustomCarousel";

const YouAreNotAlone = () => {
  return (
    <div className="flex flex-col">
      {/* <div className="w-full rounded-[20px] bg-[#E2B44B] py-20 flex justify-center">
        <div className="flex flex-col items-center gap-10 w-[790px]">
          <h2 className="text-[43px] text-white font-audioWide text-center">
            BRING YOUR IRL SCENARIOS INTO TAARA'S WORLD
          </h2>
          <p className="text-center text-white w-[464px]">
            Create roleplays mirroring your real-life challenges. Our AI adapts
            to your needs, offering personalized coaching based on what you're
            actually going through.
          </p>
        </div>
      </div> */}
      <CustomCarousel />
      <div className="youAreNotAloneBackgroundArea z-0">
        <div className="youAreNotAloneArea">
          <div className="topGraphicalAreaText">YOU ARE NOT ALONE</div>
          <div className="topGraphicalAreaParagraph">
            Conversations like these leave us feeling anxious, underprepared,
            and undervalued, with no idea where to turn next.
          </div>
          <div className="topGraphicalAreaParagraph">
            With Real World Roleplay, you get unlimited 're-dos' for every
            high-stakes conversation.
          </div>
        </div>
      </div>
      <div className="w-full rounded-[20px] bg-[#E2B44B] py-20 flex justify-center -mt-6 z-1">
        <div className="flex flex-col items-center gap-10 w-[790px]">
          <h2 className="text-[43px] text-white font-audioWide text-center">
            BRING YOUR IRL SCENARIOS INTO TAARA'S WORLD
          </h2>
          <p className="text-center text-white w-[464px] z-1">
            Create roleplays mirroring your real-life challenges. Our AI adapts
            to your needs, offering personalized coaching based on what you're
            actually going through.
          </p>
        </div>
      </div>
    </div>
  );
};

export default YouAreNotAlone;
