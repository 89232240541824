import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Landing from "./pages/Landing/Landing";
import Taara from "./pages/Taara/Taara";
import About from "./pages/About/About";
import Blog from "./pages/Blog/Blog";
import FAQ from "./pages/FAQ/FAQ";
import Ambassadors from "./pages/Ambassadors/Ambassadors";
import Assessment from "./pages/Assessment/Assessment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Profile from "./pages/Auth/Profile/Profile";
import Billing from "./pages/Auth/Billing/Billing";
import AuthCustomQuest from "./pages/Auth/CustomQuest/CustomQuest";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthLanding from "./pages/Auth/AuthLanding/AuthLanding";
import VerifyUser from "./pages/VerifyUser/VerifyUser";
import { useSelector } from "react-redux";
import Pricing from "./pages/Pricing/Pricing";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import TermsOfService from "./pages/Terms/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PitchTraining from "./pages/PitchTraining/PitchTraining";
import NotFound from "./components/NotFound/NotFound";
import CustomQuest from "./pages/CustomQuest/CustomQuest";
import TryTaara from "./pages/TryTaara/TryTaara";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <AuthLanding /> : <Landing />}
        />
        <Route path="/taara" element={<Taara />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/ambassador" element={<Ambassadors />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pitch-training" element={<PitchTraining />} />
        <Route path="/custom-quest" element={<CustomQuest />} />
        <Route path="/trytaara" element={<TryTaara />} />
        <Route
          path="/verifyUser/:userId/:verificationId"
          element={<VerifyUser />}
        />
        <Route
          path="/resetPassword/:userId/:requestId"
          element={<ResetPassword />}
        />
        {/* Auth Routes */}

        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/billing"
          element={<ProtectedRoute element={<Billing />} />}
        />
        <Route
          path="/myquest"
          element={<ProtectedRoute element={<AuthCustomQuest />} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
